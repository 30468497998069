import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  memo,
} from "react";
import PropTypes from "prop-types";
import { ThreeCircles } from "react-loader-spinner";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import Stack from "@mui/material/Stack";
import StealerLogsTable from "./StealerLogsTable";
import StealerLogsJson from "../display_json/StealerLogsJson";
import Geolocation from "../geolocatonmap/Geolocation";
import "react-toastify/dist/ReactToastify.css";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { filter_context } from "../searchbar/Searchbar";
import { leaflet_countries_color } from "../../../utils/Globals/global_variables";
import "../../../css/Stealerlogs/stealerlogs.css";

function StealerLogsFunction({ stealer_logs_ref }) {
  const [stealer_search_data, set_stealer_search_data] = useState([]);
  const [error_status, set_error_status] = useState(false);
  const [stealer_search_error, set_stealer_search_error] = useState();
  const [show_table, set_show_table] = useState(null);
  const [getSearchedData, setGetSearchedData] = useState();
  const [rate_limit_stealer, set_rate_limit_stealer] = useState();
  const [total_db_data_stealer, set_total_db_data_stealer] = useState();
  const [search_after_id, set_search_after_id] = useState(null);
  const [loadmore_loading, set_loadmore_loading] = useState(false);
  const { showFilters, stealer_logs_location } =
    useContext(filter_context)["stealer_filters"];

  const [template_filters, set_template_filters] = useState({
    template_stealer_filters: "email",
    template_display_stealer: "table",
    template_search_type_stealer: "contains",
    template_stealer_filter_type: "default",
    template_stealer_multiple_obj: {},
    template_date: {
      start: "None",
      end: "None",
    },
    template_date_log: false,
    template_selected_countries: [],
  });

  const stealer_logs_access_status = handle_session_get("stealer_logs_access");

  useEffect(() => {
    stealer_logs_ref.current = get_stealer_data;
  }, []);
  let isSubmitBlocked = false;
  const get_stealer_data = useCallback(
    (
      searchedData,
      stealer_filters,
      search_type_stealer,
      display_type_stealer,
      max_result_stealer,
      stealer_date_from,
      stealer_date_to,
      stealer_filter_type,
      stealer_multiple_obj,
      stealer_date_log,
      stealer_auto_fields,
      post_category_stealer,
      all_country_details_len,
      selected_countries,
    ) => {
      if (show_table === null && stealer_search_data.length === 0) {
        set_show_table(false);
      } else if (show_table === true && stealer_search_data.length > 0) {
        set_loadmore_loading(true);
      }
      setGetSearchedData(searchedData);
      set_template_filters({
        template_stealer_filters: stealer_filters,
        template_display_stealer: display_type_stealer,
        template_search_type_stealer: search_type_stealer,
        template_date: { start: stealer_date_from, end: stealer_date_to },
        template_stealer_filter_type: stealer_filter_type,
        template_stealer_multiple_obj: stealer_multiple_obj,
        template_date_log: stealer_date_log,
        template_stealer_auto_fields: stealer_auto_fields,
        template_selected_countries: selected_countries,
      });
      const token = localStorage.getItem("Token");
      const data = {
        qtext: searchedData,
        search_filter: stealer_filters,
        search_type: search_type_stealer,
        max_result: Number(max_result_stealer),
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        start_date: stealer_date_from,
        end_date: stealer_date_to,
        log_date_filter: stealer_date_log === "true",
        compress: true,
        category:
          post_category_stealer !== "off" ? post_category_stealer : null,
        country_code:
          selected_countries.length > 0
            ? all_country_details_len === selected_countries.length
              ? "all"
              : selected_countries
            : null,
      };
      if (stealer_filters === "email") {
        data.enable_autofill =
          stealer_auto_fields === "true" || stealer_auto_fields === true;
      }

      // Note filter custom search data stealer
      const filter_stealer_multiple_obj = {};
      for (const key in stealer_multiple_obj) {
        if (Object.prototype.hasOwnProperty.call(stealer_multiple_obj, key)) {
          filter_stealer_multiple_obj[key] =
            stealer_multiple_obj[key].length === 0
              ? null
              : stealer_multiple_obj[key];
        }
      }
      const data_multiple_search = {
        ...filter_stealer_multiple_obj,
        api_mode: 2,
        search_type: search_type_stealer,
        max_result: Number(max_result_stealer),
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        start_date: stealer_date_from,
        end_date: stealer_date_to,
        log_date_filter: stealer_date_log === "true",
        compress: true,
        category:
          post_category_stealer !== "off" ? post_category_stealer : null,
      };
      if (
        filter_stealer_multiple_obj["email"] &&
        filter_stealer_multiple_obj["email"] !== null &&
        filter_stealer_multiple_obj["email"] !== "None"
      ) {
        data_multiple_search.enable_autofill =
          stealer_auto_fields === "true" || stealer_auto_fields === true;
      }
      if (selected_countries.length > 0) {
        if (all_country_details_len === selected_countries.length) {
          data_multiple_search.country_code = "all";
        } else {
          data_multiple_search.country_code = selected_countries;
        }
      }
      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .post(
            "https://api.recordedtelegram.com/stealer_logs_search",
            stealer_filter_type === "default" ? data : data_multiple_search,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            },
          )
          .then((res) => {
            set_show_table(true);
            set_stealer_search_data([...stealer_search_data, ...res.data.data]);

            set_error_status(false);
            set_rate_limit_stealer(res.data.ratelimit);
            set_total_db_data_stealer(res.data.total_db_data);
            set_loadmore_loading(false);
            set_search_after_id(res.data.search_id);
            isSubmitBlocked = false;
          })
          .catch((err) => {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_error_status(true);
            set_show_table(true);
            set_stealer_search_error(err.response.data.errormsg);
            set_loadmore_loading(false);
            isSubmitBlocked = false;
          });
      }
    },
    [getSearchedData, search_after_id, stealer_search_data],
  );
  const {
    template_stealer_filters,
    template_display_stealer,
    template_search_type_stealer,
    template_date,
    template_stealer_filter_type,
    template_stealer_multiple_obj,
    template_date_log,
    template_stealer_auto_fields,
    template_selected_countries,
  } = template_filters;

  return (
    <>
      {show_table ? (
        error_status === true ? (
          <div className="main__table no_data">{stealer_search_error}</div>
        ) : total_db_data_stealer === 0 ? (
          <div className="main__table no_data">No data to display</div>
        ) : (
          <div>
            {template_display_stealer === "table" ? (
              <StealerLogsTable
                getSearchedData={getSearchedData}
                stealer_search_data={stealer_search_data}
                template_stealer_filters={template_stealer_filters}
                rate_limit_stealer={rate_limit_stealer}
                total_db_data_stealer={total_db_data_stealer}
                loadmore_loading={loadmore_loading}
                template_search_type_stealer={template_search_type_stealer}
                get_stealer_data={get_stealer_data}
                template_date={template_date}
                template_stealer_filter_type={template_stealer_filter_type}
                stealer_multiple_obj={template_stealer_multiple_obj}
                template_date_log={template_date_log}
                template_stealer_auto_fields={template_stealer_auto_fields}
                template_selected_countries={template_selected_countries}
              />
            ) : (
              <StealerLogsJson
                Data={stealer_search_data}
                total_db_data={total_db_data_stealer}
                telegram_limit={rate_limit_stealer}
              />
            )}
          </div>
        )
      ) : show_table !== null ? (
        <div className="spinner">
          <ThreeCircles
            color="#28cab3"
            height={60}
            width={60}
            ariaLabel="three-circles-rotating"
          />
        </div>
      ) : (
        <>
          {showFilters === false ? (
            <p
              className={
                stealer_logs_access_status === "accessed"
                  ? "geolocation_map_container_note"
                  : "geolocation_map_container_note_restricted"
              }
            >
              {stealer_logs_access_status === "accessed"
                ? "Double clicking a country will search by its country code."
                : "Your account does not have access to this feature. Please contact your service provider."}
            </p>
          ) : null}
          {stealer_logs_access_status === "accessed" ? (
            <>
              <Stack>
                <div className="leaflet_map_scale_value">
                  <p className="leaflet_map_scale_text">
                    {stealer_logs_location?.index_data?.min}
                  </p>
                  <p className="leaflet_map_scale_text">
                    {stealer_logs_location?.index_data?.result_index}
                  </p>
                  <p className="leaflet_map_scale_text">
                    {stealer_logs_location?.index_data?.result_index_2}
                  </p>
                  <p className="leaflet_map_scale_text">
                    {stealer_logs_location?.index_data?.result_index_3}
                  </p>
                  <p className="leaflet_map_scale_text">
                    {stealer_logs_location?.index_data?.max}
                  </p>
                </div>
                <div className="leaflet_map_scale_container">
                  <p className="leaflet_map_scale_text">LOW</p>
                  {leaflet_countries_color.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="leaflet_map_scale_container_child"
                        style={{ background: item }}
                      />
                    );
                  })}
                  <p className="leaflet_map_scale_text">HIGH</p>
                </div>
              </Stack>
              <Geolocation />
            </>
          ) : null}
        </>
      )}
      <ToastContainer />
    </>
  );
}
StealerLogsFunction.propTypes = {
  stealer_logs_ref: PropTypes.object,
};
export default memo(StealerLogsFunction);
