import axios from "axios";
import {
  handle_error_message,
  handle_success_message,
} from "../ResponseMessage/response_message";
import { handle_token_expire } from "../Validation/Token/token_checker";
import {
  handle_session_get,
  handle_session_set,
} from "../SessionStorage/handle_session";
import { geolocation_details } from "../../TableUi/components/geolocatonmap/data";

export const add_custom_preference = (
  channel_name,
  sub_pref_type,
  dashboard_filter,
  pref_type,
  set_channels = null,
  status_type,
) => {
  const token = handle_session_get("Token");
  const data = {
    keyword: channel_name.trim(),
    sub_preference_type:
      sub_pref_type === "user_preference" ? "user" : "company",
    preference_type: pref_type,
    dashboard_filter: dashboard_filter,
  };
  axios
    .post("https://api.recordedtelegram.com/v2/add_custom_preference", data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      handle_success_message(res.data.message);
      const chart_obj = res.data.data;
      let filter_response = {
        company_preference: [],
        user_preference: [],
      };
      for (let pref in chart_obj) {
        for (let type in chart_obj[pref]) {
          for (let obj in chart_obj[pref][type]) {
            chart_obj[pref][type][obj].forEach((element) => {
              const filterItem = {
                sub_pref_type: pref,
                pref_type: type,
                dashboard_filter: obj,
              };
              if (
                type === "telegram_user" &&
                Object.prototype.toString.call(element) === "[object Object]"
              ) {
                filterItem["keyword"] = element.userid;
                filterItem["username"] =
                  element.username && element.username !== "None"
                    ? element.username
                    : element.userfullname && element.userfullname !== "None"
                    ? element.userfullname
                    : element.userid;
              } else {
                filterItem["keyword"] = element;
              }
              if (pref === "company_preference") {
                filter_response.company_preference.push(filterItem);
              } else {
                filter_response.user_preference.push(filterItem);
              }
            });
          }
        }
      }
      if (status_type === "custom") {
        set_channels(filter_response);
      } else if (status_type === "table") {
        set_channels((prev) => ({
          ...prev,
          marked_channels: filter_response,
        }));
      }
    })
    .catch((err) => {
      const token_expire_status = handle_token_expire(err.response.data);
      if (token_expire_status === true) return;
      handle_error_message(err.response.data.errormsg);
    });
};
export const get_custom_preference = (set_channels, status_type) => {
  const token = handle_session_get("Token");
  axios
    .get("https://api.recordedtelegram.com/v2/get_custom_preference", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const chart_obj = res.data.data;
      let filter_response = {
        company_preference: [],
        user_preference: [],
      };
      for (let pref in chart_obj) {
        for (let type in chart_obj[pref]) {
          for (let obj in chart_obj[pref][type]) {
            chart_obj[pref][type][obj].forEach((element) => {
              const filterItem = {
                sub_pref_type: pref,
                pref_type: type,
                dashboard_filter: obj,
              };
              if (
                type === "telegram_user" &&
                Object.prototype.toString.call(element) === "[object Object]"
              ) {
                filterItem["keyword"] = element.userid;
                filterItem["username"] =
                  element.username && element.username !== "None"
                    ? element.username
                    : element.userfullname && element.userfullname !== "None"
                    ? element.userfullname
                    : element.userid;
              } else {
                filterItem["keyword"] = element;
              }
              if (pref === "company_preference") {
                filter_response.company_preference.push(filterItem);
              } else {
                filter_response.user_preference.push(filterItem);
              }
            });
          }
        }
      }
      if (status_type === "custom") {
        set_channels(filter_response);
      } else if (status_type === "table") {
        set_channels((prev) => ({
          ...prev,
          marked_channels: filter_response,
        }));
      }
    })
    .catch((err) => {
      const token_expire_status = handle_token_expire(err.response.data);
      if (token_expire_status === true) return;
      if (status_type === "custom") {
        set_channels((prev) => ({
          ...prev,
          error: err.response.data.errormsg,
        }));
      }
    });
};

export const delete_custom_preference = (
  channel_name,
  sub_pref_type,
  dashboard_filter,
  pref_type,
  set_channels = null,
  status_type,
) => {
  const token = handle_session_get("Token");
  const data = {
    keyword: channel_name.trim(),
    sub_preference_type:
      sub_pref_type === "user_preference" ? "user" : "company",
    preference_type: pref_type,
    dashboard_filter: dashboard_filter,
  };
  axios
    .post(
      "https://api.recordedtelegram.com/v2/delete_custom_preference",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      },
    )
    .then((res) => {
      handle_success_message(res.data.message);
      const chart_obj = res.data.data;
      let filter_response = {
        company_preference: [],
        user_preference: [],
      };
      for (let pref in chart_obj) {
        for (let type in chart_obj[pref]) {
          for (let obj in chart_obj[pref][type]) {
            chart_obj[pref][type][obj].forEach((element) => {
              const filterItem = {
                sub_pref_type: pref,
                pref_type: type,
                dashboard_filter: obj,
              };
              if (
                type === "telegram_user" &&
                Object.prototype.toString.call(element) === "[object Object]"
              ) {
                filterItem["keyword"] = element.userid;
                filterItem["username"] =
                  element.username && element.username !== "None"
                    ? element.username
                    : element.userfullname && element.userfullname !== "None"
                    ? element.userfullname
                    : element.userid;
              } else {
                filterItem["keyword"] = element;
              }
              if (pref === "company_preference") {
                filter_response.company_preference.push(filterItem);
              } else {
                filter_response.user_preference.push(filterItem);
              }
            });
          }
        }
      }
      if (status_type === "custom") {
        set_channels(filter_response);
      } else if (status_type === "table") {
        set_channels((prev) => ({
          ...prev,
          marked_channels: filter_response,
        }));
      }
    })
    .catch((err) => {
      const token_expire_status = handle_token_expire(err.response.data);
      if (token_expire_status === true) return;
      handle_error_message(err.response.data.errormsg);
    });
};

export const get_stealer_location_detains = (
  set_location,
  set_all_countries_detail,
) => {
  const token = handle_session_get("Token");
  axios
    .get("https://api.recordedtelegram.com/stealer_log_dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const location_details = res.data.data;
      const sanitize_data = location_details.map((item) => {
        let find_country_name = item.country_name
          ? item.key === "CG"
            ? "Republic of the Congo"
            : item.country_name
          : "";
        const locate_data = geolocation_details.find(
          (loc) =>
            loc.properties.name.toLowerCase().trim() ===
            (find_country_name && find_country_name.toLowerCase().trim()),
        );
        if (locate_data) {
          return {
            ...item,
            location: { type: "FeatureCollection", features: [locate_data] },
            country_name:
              item.key === "CG" ? "Republic of the Congo" : item.country_name,
          };
        }
      });
      const filter_sanitize_data = sanitize_data.filter(
        (item) => item !== undefined,
      );
      const colone_sanitize_data = [...filter_sanitize_data].map((item) =>
        parseFloat(
          ((item["doc_count"] / item["population"]) * 1000).toFixed(3),
        ),
      );
      colone_sanitize_data.sort((a, b) => a - b);
      const groupSize = Math.ceil(colone_sanitize_data.length / 5);
      const result = [];
      for (let i = 0; i < 5; i++) {
        const startIndex = i * groupSize;
        const endIndex = Math.min(
          startIndex + groupSize,
          colone_sanitize_data.length,
        );
        const group = colone_sanitize_data.slice(startIndex, endIndex);
        result.push(group[group.length - 1]);
      }
      set_location({
        map_array: filter_sanitize_data,
        index_data: {
          min: colone_sanitize_data[0],
          max: Math.max(...colone_sanitize_data),
          result_index: result[1],
          result_index_2: result[2],
          result_index_3: result[3],
        },
      });
      const country_accessed = handle_session_get("country_code_access");
      const parsed_country =
        country_accessed === "all" ? "all" : JSON.parse(country_accessed);
      if (parsed_country) {
        if (parsed_country === "all") {
          set_all_countries_detail(location_details);
        } else {
          const filter_countries = parsed_country.map((item) =>
            location_details.find((val) => val.key === item),
          );
          set_all_countries_detail(filter_countries);
        }
      }
      if (handle_session_get("stealer_logs_access") === "restricted") {
        handle_session_set("stealer_logs_access", "accessed");
      }
    })
    .catch((err) => {
      const token_expire_status = handle_token_expire(err.response.data);
      if (token_expire_status === true) return;
      if (err.response.status === 403) {
        handle_session_set("stealer_logs_access", "restricted");
      } else {
        handle_session_set("stealer_logs_access", "accessed");
      }
      return;
    });
};

export const get_all_country_details = (set_countries) => {
  const token = handle_session_get("Token");
  axios
    .get("https://api.recordedtelegram.com/stealer_log_dashboard", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const location_details = res.data.data;
      set_countries(location_details);
      if (handle_session_get("stealer_logs_access") === "restricted") {
        handle_session_set("stealer_logs_access", "accessed");
      }
    })
    .catch((err) => {
      const token_expire_status = handle_token_expire(err.response.data);
      if (token_expire_status === true) return;
      if (err.response.status === 403) {
        handle_session_set("stealer_logs_access", "restricted");
      } else {
        handle_session_set("stealer_logs_access", "accessed");
      }
      return;
    });
};
