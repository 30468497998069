import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataTable, { defaultThemes } from "react-data-table-component";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SettingsIcon from "@mui/icons-material/Settings";
import { Grid } from "@mui/material";
import axios from "axios";
import LimitReached from "../../TableUi/components/modalContainer/LimitReached ";
import {
  RollerAnimation,
  NoData,
} from "../../TableUi/components/loader/Loader";
import AdminBulkActDeact from "../../TableUi/components/modalContainer/AdminBulkActDeact";
import { all_user_data } from "../../actions/adminAction";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import { date_fm } from "../../Assets/globalfunctions";
import {
  handle_success_message,
  handle_error_message,
} from "../../utils/ResponseMessage/response_message";

function Admintable({ admin_reducer, all_user_data }) {
  const token = handle_session_get("Token");
  const admin_status = handle_session_get("admin_status");
  const [search_data, set_search_data] = useState([]);
  const [dropdown, set_drop_down] = useState(false);
  const [search_filter, set_search_filter] = useState("UserName");
  const [search_value, set_search_value] = useState("");

  const [selected_rows, set_selected_rows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  const [open_modal_limit, set_open_modal_limit] = useState(false);
  const [open_admin_bulk, set_open_admin_bulk] = useState(false);

  const [indv_data, set_indv_data] = useState("");
  const handleRowSelected = ({ selectedRows }) => {
    const all_user_email = selectedRows.map((item) => item.email);
    set_selected_rows(all_user_email);
  };

  const sort_reset_column = (rowA, rowB) => {
    const a = rowA.acc_expiry_date;
    const b = rowB.acc_expiry_date;
    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const activate_selected_users = (auth) => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
    const data = {
      email_list: selected_rows,
      set_auth_to: auth,
      delete_selected: "False",
    };

    axios
      .post("https://api.recordedtelegram.com/v2/bulkOps", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        const text = auth === "True" ? "activated" : "deactivated";
        handle_success_message(`Email ${selected_rows.toString()} ${text}`);
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        setToggleClearRows(true);
        set_selected_rows([]);
        setTimeout(() => all_user_data(), 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(
          "Sorry operation failed. Please contact service provider",
        );
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        setToggleClearRows(true);
        set_selected_rows([]);
      });
  };

  const delete_bulk_users = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
    const data = {
      email_list: selected_rows,
      delete_password:
        "aa4b3f95c5a2e0b38be795d0c9be07e3f73f8dc80cee9fc2cc859b029199cc10",
      delete_selected: "True",
      set_auth_to: "None",
    };

    axios
      .post("https://api.recordedtelegram.com/v2/bulkOps", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handle_success_message("Successfully Deleted");
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        setToggleClearRows(true);
        set_selected_rows([]);
        setTimeout(() => all_user_data(), 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(
          "Sorry deletion failed. Please contact service provider",
        );
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        setToggleClearRows(true);
        set_selected_rows([]);
      });
  };

  const conditionalRowStyles = [
    {
      when: (row) => selected_rows.includes(row.email),
      style: {
        ".rdt_TableCell": {
          background: "#5d656c",
        },
      },
    },
  ];

  useEffect(() => {
    toast.configure();
    all_user_data();
  }, []);

  const delete_user = (id, e) => {
    e.preventDefault();
    const data = { id };
    axios
      .post("https://api.recordedtelegram.com/v2/admin/deleteclient", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        if (search_value.length > 0) {
          const new_data = search_data.filter((item) => item.userid !== id);
          set_search_data(new_data);
          handle_success_message(
            "The requested user has been deleted from the system.",
          );
        } else {
          all_user_data();
        }
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };

  const reset_user = (id, e) => {
    e.preventDefault();
    const data = { user_id: id };
    axios
      .post("https://api.recordedtelegram.com/admin/resetacc", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handle_success_message("Successfully Reset");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Sorry Reset failed");
      });
  };
  const search_change = (val) => {
    const new_search_value = val.target.value.toLowerCase();
    set_search_value(val.target.value);
    const main_val = admin_reducer.all_data;
    if (search_filter === "UserName") {
      const new_val = main_val.filter((val) =>
        val.username.toLowerCase().includes(new_search_value),
      );
      set_search_data(new_val);
    } else {
      const new_val = main_val.filter((val) =>
        val.email.toLowerCase().includes(new_search_value),
      );
      set_search_data(new_val);
    }
  };

  function ExpanableComponent({ data }) {
    return (
      <div className="admin_expand_row">
        <div className="column_expand">
          <b>User id:</b>
          {data.userid}
        </div>
        <div className="column_expand">
          <b> username:</b>
          {JSON.stringify(data.username)}
        </div>
        <span className="column_expand">
          <b>email:</b>
          {JSON.stringify(data.email)}
        </span>
        <span className="column_expand">
          <b>Account Type:</b>
          {JSON.stringify(data.customer_type)}
        </span>
        <span className="column_expand">
          <b>Last login:</b>
          {JSON.stringify(data.lastlogin_at)}
        </span>
        <span className="column_expand">
          <b>Last login IP:</b>
          {JSON.stringify(data.lastloginip)}
        </span>
        <span className="column_expand">
          <b>Ratelimit:</b>
          {data.ratelimit}
        </span>
        <span className="column_expand">
          <b>Sign up IP:</b>
          {JSON.stringify(data.signupip)}
        </span>
        <span className="column_expand">
          <b>Superadmin:</b>
          {JSON.stringify(data.superadmin)}
        </span>
        <span className="column_expand">
          <b>Authorization:</b>
          {JSON.stringify(data.authorization)}
        </span>
        <span className="column_expand">
          <b>Registration Date:</b>
          {JSON.stringify(data.dateofregistration)}
        </span>
      </div>
    );
  }
  ExpanableComponent.propTypes = {
    data: PropTypes.object,
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "56px",
        background: "#2b3c46",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        background: "#2b3c46",
        color: "#dcf3ff",
        fontFamily: "monospace",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
          fontSize: "16px",
          color: "#dcf3ff",
          fontFamily: "monospace",
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
          fontSize: "14px",
          background: "#2b3c46",
          color: "#9bbcd1",
        },
      },
    },
  };
  const data =
    search_value.length > 0 ? search_data : admin_reducer.all_data ?? [];
  const customer_role = {
    user: "Trial",
    premium_user: "Paid",
    company_admin: "Company Admin",
    marketing: "Marketing",
    admin: "Admin",
    super_admin: "Super Admin",
  };

  const custom_column_login_sort = (rowA, rowB) => {
    const a = new Date(rowA.lastlogin_at);
    const b = new Date(rowB.lastlogin_at);

    if (a > b) {
      return -1;
    }

    if (b > a) {
      return 1;
    }

    return 0;
  };

  const column = [
    {
      name: "ID",
      selector: "userid",
      sortable: true,
      width: "fit-content",
    },
    {
      name: "Username",
      selector: "username",
      sortable: true,
      width: "200px",
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
      flex: 1,
    },
    {
      name: "Ratelimit",
      selector: "ratelimit",
      sortable: true,
      width: "110px",
    },
    {
      name: "Auth",
      selector: "authorization",
      sortable: true,
      width: "75px",
      fontSize: "14px",
    },
    {
      name: "Account",
      sortable: false,
      width: "125px",
      fontSize: "16px",
      selector: (row) => <div>{customer_role[row.customer_type]}</div>,
    },
    {
      name: "Login IP",
      selector: "lastloginip",
      sortable: false,
      width: "133px",
      fontSize: "16px",
    },

    {
      name: (
        <span
          style={{
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
            fontSize: "16px",
            color: "#dcf3ff",
          }}
        >
          Last Login
        </span>
      ),
      selector: (row) => {
        const date_txt = new Date(row.lastlogin_at);
        const date_to_iso = date_txt.toISOString();
        return date_fm(date_to_iso);
      },
      sortable: true,
      sortFunction: custom_column_login_sort,
      width: "135px",
    },
    {
      name: (
        <span
          style={{
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
            fontSize: "16px",
            width: "150px",
            color: "#dcf3ff",
          }}
        >
          Edit
        </span>
      ),
      selector: (row) => (
        <Link to={`/prof?page=${row.userid}`}>
          <SettingsIcon className="admin_edit_icon" />
        </Link>
      ),
      sortable: false,
      width: "60px",
    },
    {
      name: (
        <span
          style={{
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
            fontSize: "16px",
            width: "110px",
            color: "#dcf3ff",
            marginLeft: "20px",
          }}
        >
          Reset
        </span>
      ),
      selector: (row) =>
        row.acc_expiry_date !== "None" ? (
          <button
            disabled={admin_status !== "super_admin"}
            data-toggle="modal"
            style={{
              marginLeft: "0.5%",
              color: "black",
              background:
                admin_status !== "super_admin" ? "#b18e24" : "#e5b117",
              border: "none",
              outline: "none",
            }}
            data-target="#reset_user"
            className="btn"
            onClick={(e) => {
              set_indv_data(row.userid);
              e.preventDefault();
            }}
          >
            Reset
          </button>
        ) : row.authorization === "True" ? (
          <div className="user_active">Active</div>
        ) : (
          <div className="user_active">Inactive</div>
        ),
      sortable: true,
      sortFunction: sort_reset_column,
      width: "110px",
    },
    {
      name: (
        <span
          style={{
            borderRightStyle: "solid",
            borderRightWidth: "1px",
            borderRightColor: defaultThemes.default.divider.default,
            fontSize: "16px",
            width: "150px",
            color: "#dcf3ff",
            marginLeft: "20px",
          }}
        >
          Delete
        </span>
      ),
      selector: (row) => (
        <button
          disabled={admin_status !== "super_admin"}
          style={{ marginLeft: "5px" }}
          data-toggle="modal"
          data-target="#showdeletebutton"
          className="btn btn-danger"
          onClick={(e) => {
            set_indv_data(row.userid);
            e.preventDefault();
          }}
        >
          Delete
        </button>
      ),
      sortable: false,
      width: "120px",
    },
  ];

  return (
    <section id="boxes" className="">
      <div className="container">
        <div className="row mb-1 " style={{ justifyContent: "center" }}>
          <div className="col-lg-7">
            <div
              className="  rounded rounded-pill  shadow-sm mb-4"
              style={{ border: ".5px solid #1FCECB", padding: "0.4rem" }}
            >
              <form autoComplete="off">
                <div className="input-group">
                  <div
                    onClick={() => {
                      set_drop_down(!dropdown);
                    }}
                    className="dropdowninp"
                  >
                    <div className="default_option">{search_filter}</div>
                    <ul className={dropdown ? "active shadow-lg" : null}>
                      <li
                        onClick={() => {
                          set_search_filter("UserName");
                          set_drop_down(false);
                        }}
                      >
                        UserName
                      </li>
                      <li
                        onClick={() => {
                          set_search_filter("Email");
                          set_drop_down(false);
                        }}
                      >
                        Email
                      </li>
                    </ul>
                  </div>

                  <input
                    id="srch_tab"
                    type="search"
                    placeholder="Search here ..."
                    name="input_admin_search"
                    value={search_value}
                    onChange={(e) => {
                      search_change(e);
                    }}
                    className="form-control border-0 new-dk"
                  />

                  <button
                    id="button-addon1"
                    type="submit"
                    style={{
                      outline: "none",
                      outlineWidth: 0,
                      boxShadow: "none",
                    }}
                    className="btn btn-link"
                  >
                    <FontAwesomeIcon
                      style={{ color: "#1FCECB" }}
                      icon={faSearch}
                      size="1x"
                    />
                  </button>
                  {/* </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="row"
        style={{ marginBottom: "100px", justifyContent: "center" }}
      >
        <Grid container>
          <Grid item xs={7}>
            {selected_rows.length > 0 ? (
              <div className="admin_user_btns">
                <button
                  disabled={admin_status !== "super_admin"}
                  onClick={() => activate_selected_users("True")}
                  className="activate_user"
                >
                  Activate
                </button>
                <button
                  disabled={admin_status !== "super_admin"}
                  onClick={() => activate_selected_users("False")}
                  className="deactivate_user"
                >
                  Deactivate
                </button>
                <button
                  disabled={admin_status !== "super_admin"}
                  className="delete_user"
                  onClick={() => set_open_modal_limit(true)}
                >
                  Delete
                </button>
              </div>
            ) : null}
          </Grid>
          <Grid item xs={4.5}>
            {data.length > 0 ? (
              <div className="bulk_activate_deactivate">
                <button onClick={() => set_open_admin_bulk(true)}>
                  Bulk activate/deactivate
                </button>
                <Link
                  to="/admin/create-multiple-accounts"
                  className="link_multiple_accounts"
                >
                  Create company accounts
                </Link>
              </div>
            ) : null}
          </Grid>
        </Grid>

        <div
          className="modal fade"
          id="downloadmodal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="downloadmodal"
          aria-hidden="true"
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-confirm"
            style={{ background: "#2b3c46", marginTop: "13rem" }}
          >
            <div className="modal-content dk-mode">
              <div className="modal-header flex-column">
                <h4
                  className="modal-title w-100"
                  style={{ fontSize: "18px", color: "#359daf" }}
                >
                  Please wait,
                </h4>
              </div>
              <div className="modal-body" style={{ marginTop: "50px" }}>
                <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  >
                    {" "}
                  </div>
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <span style={{ left: "2%", color: "#359daf" }} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-sm-12 col-lg-12 ">
          {search_value.length > 0 && data.length === 0 ? (
            <NoData />
          ) : data.length === 0 ? (
            <RollerAnimation />
          ) : (
            <div className="x_panel">
              <div className="x_content shadow-lg">
                <div className="table-responsive">
                  <DataTable
                    data={data}
                    columns={column}
                    customStyles={customStyles}
                    noHeader
                    pagination
                    paginationPerPage={100}
                    selectableRows
                    onSelectedRowsChange={handleRowSelected}
                    paginationRowsPerPageOptions={[25, 50, 75, 100, 200]}
                    expandableRows
                    expandableRowsComponent={<ExpanableComponent />}
                    conditionalRowStyles={conditionalRowStyles}
                    clearSelectedRows={toggledClearRows}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="showdeletebutton"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="showdeletebutton"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div className="modal-dialog modal-confirm">
          <div className="modal-content" style={{ background: "#2b3c46" }}>
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100" style={{ color: "white" }}>
                Are you sure?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                style={{ color: "white" }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p style={{ color: "whitesmoke" }}>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();

                  delete_user(indv_data, e);
                  document.getElementById("showdeletebutton").className =
                    "modal fade";
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for the reseting feature of the user */}
      <div
        className="modal fade"
        id="reset_user"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="reset_user"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div className="modal-dialog modal-confirm">
          <div className="modal-content" style={{ background: "#2b3c46" }}>
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100" style={{ color: "white" }}>
                Are you sure?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
                style={{ color: "white" }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p style={{ color: "whitesmoke" }}>
                Do you really want to Reset these records? This process cannot
                be undone.
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();

                  reset_user(indv_data, e);
                  document.getElementById("reset_user").className =
                    "modal fade";
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
      <LimitReached
        open_modal_limit={open_modal_limit}
        set_open_modal_limit={set_open_modal_limit}
        delete_bulk_users={delete_bulk_users}
      />
      <AdminBulkActDeact
        open_admin_bulk={open_admin_bulk}
        set_open_admin_bulk={set_open_admin_bulk}
        all_user_data={all_user_data}
      />
    </section>
  );
}
const mapStateToProps = (state) => ({
  admin_reducer: state.admin_reducer,
});
Admintable.propTypes = {
  admin_reducer: PropTypes.object,
  all_user_data: PropTypes.func,
  data: PropTypes.array,
};
export default connect(mapStateToProps, { all_user_data })(Admintable);
